
.coach-card {
	padding: 0px 12px;
	width: 320px;
	padding-top: 100px;
}

	.coach-card-container {
		background: #1A1C24;
		border-radius: 24px;
		padding-top: 100px;
		position: relative;
	}
		.coach-card-image-container {
			position: absolute;
			width: 76%;
			left: 12%;
			top: -120px;
			
			box-shadow: 0px 0px 24px 12px rgba(0, 0, 0, 0.2);
		}
			.coach-card-image {
				border-radius: 16px;
				overflow: hidden;
			}
		.coach-card-content-container {
			width: 100%;
			padding: 0px;
		}
			.coach-card-content-header-container {
				width: 100%;
				height: 64px;
				padding: 20px 0px;
			}
				.coach-card-content-header-container p {
					color: #D9DCE5;
					font-size: 14px;
					width: 100%;
					text-align: center;
					margin: 0px;
				}
				.coach-card-content-header-container h2 {
					color: #ffffff;
					font-size: 30px;
					width: 100%;
					text-align: center;
					margin: 0px;
				}
			.coach-card-content-body-container {
				width: 100%;
				padding: 20px 20px;
				height: 140px;
			}
				.coach-card-content-body-container p {
					color: #9DA0AD;
					font-size: 18px;
					width: 100%;
					text-align: center;
					margin: 0px;
				}

			.coach-card-content-button-container {
				width: 100%;
				border-top: solid;
				border-width: 1px;
				border-color: #2E3142;
				padding: 20px;
				padding-bottom: 22px;
				border-bottom-right-radius: 24px;
				border-bottom-left-radius: 24px;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.coach-card-content-button-container-active:hover {
				background: #232836;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.coach-card-content-button-container p {
					color: #9DA0AD;
					font-size: 18px;
					width: 100%;
					text-align: center;
					margin: 0px;
				}
				.coach-card-content-button-container-active p {
					color: #7278F2;
				}
				.coach-card-content-button-container-disabled {

				}



@media only screen and (min-width: 1500px) {}
@media only screen and (max-width: 1500px) {}
@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 992px) {
    .coach-card {
    	padding: 0px 6px;
		width: 320px;
		padding-top: 100px;
    }
    .coach-card-image-container {
    	top: -12vw;
    }
    .coach-card-container {
    	padding-top: calc(12vw);
    }
    .coach-card-content-body-container {
    	height: 160px;
    }
}

@media only screen and (max-width: 768px) {
    .coach-card {
    	width: 100%;
    	margin-bottom: calc((100vw - 160px) * 0.4);;
    }
    .coach-card-image-container {
    	width: 80%;
    	top: calc((100vw - 60px) * -0.4);
    }
    .coach-card-container {
    	padding-top: calc((100vw - 20px) * 0.4);
    }
    .coach-card-content-body-container {
    	height: auto;
    	padding-bottom: 40px;
    }
}

@media only screen and (max-width: 576px) {
    
}




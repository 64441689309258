
.page-form-inner-input-text-button-loading-wrapper {
    width: 100%;
    height: 100%;
    padding-top: 4px;
}
    .email-loading-spinner {
        margin: 0px auto;
        margin-top: -3px;
        width: 24px !important;
        height: 24px !important;
        border: 3px solid rgba(0,0,0,0); /* Light grey */
        border-top: 3px solid #ffffff !important;
        border-right: 3px solid #ffffff !important;
        border-bottom: 3px solid #ffffff !important;
        border-radius: 12px !important;
        animation: spin 0.8s linear infinite;
    }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

.email-error-container {
    width: 100%;
    padding: 20px 0px;
}
    .email-error-container p {
        font-size: 18px;
        color: #7278F2;
        margin: 0px;
        width: 100%;
        text-align: center;
    }
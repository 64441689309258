/* .improving-card- */

.improving-card {
	display: flex;
	background: #1A1C24;
	border-radius: 12px;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.04);
	width: calc(100%);
	max-width: 600px;
	margin: 2px auto;
	height: auto;
	border: solid;
	border-width: 2px;
	border-color: transparent;
	position: relative;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.improving-card:hover {
	cursor: pointer;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 100, 0.06);
	border-color:  #5031D5;
    background: #232836;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.improving-card-selected {
	background: rgba(198, 138, 255, 0.2);
	border-color: #B262FD;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 100, 0);
}
.improving-card-selected .improving-card-icon-bg {
	background: #B262FD;
}

	.improving-card-check-container {
		position: absolute;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background: #B262FD;
		top: -12px;
		right: -12px;
		padding: 5px 5px;
		box-shadow: 0px 2px 6px 3px rgba(0, 0, 100, 0.08);
	}
		.improving-card-check {
			font-size: 15px !important;
		}
	.improving-card-inner {
		display: flex;
		padding: 24px;
		height: 100%;
		align-items: center; /* align vertical */
	}
		.improving-card-icon-container {

		}
			.improving-card-icon-bg {
				width: 50px;
				height: 50px;
				background: #5031D5;
				border-radius: 5px;
				padding: 9px;
			}
				.improving-card-icon-bg img {
					width: 32px;
					height: 32px;
					filter: invert(1);
				}


		.improving-card-title-container {
			padding-left: 20px;
			margin-top: -4px;
		}
			.improving-card-title-container h3 {
			    font-size: 22px;
			    color: #ffffff;
			    margin: 0px;
			}




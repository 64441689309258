.confirm {
	padding: 0px 12px;
}
	.confirm .page-form-inner-question-container {
		width: 100%;
		max-width: 800px;
		margin: 0px auto;
	}

	.confirm-image {
		width: 180px;
		margin: 0px calc(50% - 90px);
		margin-bottom: 16px;
	}

	.confirm-loading-container {
		margin-top: 25vh;
	}
		

.confirm-app-icon {
	width: 100px;
	height: 100px;
	min-height: 100px;
	border-radius: 24px;
	border: solid;
	border-width: 1px;
	border-color: #2E3142;
	background: #000000;
	justify-content: center;
	align-items: center;
	padding-top: 29px;
	margin: 0px auto;
	margin-bottom: 40px;
	box-shadow: 0px 0px 48px 24px #5B62FF20;
}
	.confirm-app-icon p {
		height: 20px;
		color: white;
		font-weight: 500;
		font-size: 28px;
	}

	.confirm-download-button {
		margin: 0px auto;
		margin-top: 80px;
		width: 260px;
		background: #5B62FF;
		padding: 16px;
		border-radius: 14px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
	.confirm-download-button:hover {
		background: #7278F2;
		cursor: pointer;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.confirm-download-button p {
			height: 20px;
			color: white;
			font-weight: 500;
			font-size: 18px;
		}


@media only screen and (max-width: 768px) {
	.confirm-download-button {
		width: 100%;
	}
}

@media only screen and (max-width: 576px) {
	
}
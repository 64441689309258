

.payment-form-header {
	width: 100%;
}
	.payment-form-header h3 {
	    font-size: 22px;
	    color: #000000;
	    margin: 8px 0px;
	}
	.payment-form-header p {
	    font-size: 16px;
	    color: #454751;
	    margin: 8px 0px;
	    margin-bottom: 32px;
	}

	.payment-error {
		padding-top: 16px;
		color: #FF4D4D;
	}


	.payment-loading-spinner {
	    margin: 0px auto;
	    margin-top: -3px;
	    width: 24px;
	    height: 24px;
	    border: 3px solid rgba(0,0,0,0); /* Light grey */
	    border-top: 3px solid #ffffff;
	    border-right: 3px solid #ffffff;
	    border-bottom: 3px solid #ffffff;
	    border-radius: 12px;
	    animation: spin 0.8s linear infinite;
	}
	    @keyframes spin {
	        0% { transform: rotate(0deg); }
	        100% { transform: rotate(360deg); }
	    }


.payment {
	width: 100%;
	max-width: 600px;
	margin: 0px auto;
	padding: 0px 12px;
	padding-top: 100px;
	padding-bottom: 20px;
}

	.payment-card {
		background: #ffffff;
		padding: 32px 24px;
		border-radius: 12px;
		box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.04);
		height: 520px;
	}
		.payment-summary-container {

		}
			.payment-summary-header {

			}
				.payment-summary-header h4 {
				    font-size: 32px;
				    color: #000000;
				    margin: 0px;
				    margin-bottom: 12px;
				}
				.payment-summary-header p {
				    font-size: 22px;
				    color: #454751;
				    margin: 0px;
				}
			.payment-summary-order-summary {
				padding: 16px 0px;
			}
				.payment-summary-order-header {
					border-bottom: solid;
					border-width: 1px;
					border-color: #C0C1C6;
				}
					.payment-summary-order-header h4 {
					    font-size: 22px;
					    color: #000000;
					    margin: 12px 0px;
					    font-weight: 400;
					}
				.payment-summary-order-body {
					padding: 12px 0px;
				}
					.payment-summary-order-body-row {
						display: flex;
						width: 100%;
						padding: 8px 0px;
					}
						.payment-summary-order-body-row-desc {
							width: calc(100% - 80px);
							display: flex;
						}
							.payment-summary-order-body-row-desc p {
							    font-size: 18px;
							    color: #454751;
							    margin: 0px;
							    width: 100%;
							}
						.payment-summary-order-body-row-amount {
							width: 80px;
						}
							.payment-summary-order-body-row-amount h4 {
							    font-size: 22px;
							    color: #000000;
							    margin: 0px;
							    text-align: right;
							    width: 100%;
							}
						.payment-summary-order-body-row-discount {
							position: relative;
						}
							.payment-summary-order-body-row-discount p {
								color: #B262FD;
							}
							.payment-summary-order-body-row-discount h4 {
								color: #B262FD;
							}
							.payment-summary-order-body-row-discount-code-container {
								float: left;
								position: absolute;
								left: 150px;
								background: #B262FD;
								border-radius: 5px;
								box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.04);
								padding: 3px 8px;
								margin-top: 1px;
							}
								.payment-summary-order-body-row-discount-code-container p {
									font-size: 14px;
									color: #ffffff;
									font-weight: 600;
									font-family: 'Courier New', monospace;
								}
						.payment-summary-order-final {
							width: 100%;
							border-top: solid;
							border-width: 1px;
							border-color: #C0C1C6;
							padding: 12px 0px;
							display: flex;
						}
							.payment-summary-order-final .payment-summary-order-body-row-amount {
								width: 100%;
							}
								.payment-summary-order-final .payment-summary-order-body-row-amount h4 {
									font-size: 32px;
									white-space: nowrap;
								}

								.payment-summary-order-final .payment-summary-order-body-row-desc p {
									margin-top: 8px;
								}
			.payment-summary-button {
				width: 100%;
				background: #5B62FF;
				padding: 16px;
				margin-top: 36px;
				border-radius: 14px;
				box-shadow: 0px 0px 6px 3px rgba(0, 0, 50, 0.08);
				
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.payment-summary-button:hover {
				cursor: pointer;
				background: #7278F2;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.payment-summary-button p {
					color: #ffffff;
					opacity: 1;
					font-size: 18px;
					width: 100%;
					text-align: center;
					margin: 0px;
				}
		
	.payment form {
		
	}
	.payment button {
		width: 100%;
		background: #5031D5;
		padding: 14px;
		margin-top: 32px;
		border-radius: 5px;
		box-shadow: 0px 0px 6px 3px rgba(198, 138, 255, 0.28);
		color: #ffffff;
		font-size: 18px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.payment button span {
			color: #ffffff;
			font-size: 18px;
		}
		.payment button:hover {
			background: #7278F2;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}

.payment-info-loading-container {
	width: 100%;
	padding-top: 12px;
}
	.payment-info-loading-container p {
	    font-size: 16px;
	    color: #9DA0AD;
	    margin: 0px;
	    margin-top: 12px;
	    width: 100%;
	    text-align: center;
	}
.payment-loading-spinner {
    margin: 0px auto;
    margin-top: -3px;
    width: 24px !important;
    height: 24px !important;
    border: 3px solid rgba(0,0,0,0); /* Light grey */
    border-top: 3px solid #5B62FF !important;
    border-right: 3px solid #5B62FF !important;
    border-bottom: 3px solid #5B62FF !important;
    border-radius: 12px !important;
    animation: spin 0.8s linear infinite;
}
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

@media only screen and (max-width: 388px) {
	.payment-summary-order-body-row-discount-code-container {
		left: 0px;
		top: 28px;
	}
}




/* .gender-card- */

.gender-card {
    display: block;
    background: #1A1C24;
    border-radius: 12px;
    box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.04);
    width: 100%;
    margin: 2px auto;
    height: 80px;
    border: solid;
    border-width: 2px;
    border-color: transparent;
    position: relative;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}
.gender-card:hover {
    cursor: pointer;
    box-shadow: 0px 0px 12px 6px rgba(0, 0, 100, 0.06);
    border-color:  #5031D5;
    background: #232836;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    transition: all 0.15s;
}

.gender-card-selected {
    background: rgba(91, 98, 255, 0.2);
    border-color: #5031D5;
    box-shadow: 0px 0px 12px 6px rgba(0, 0, 100, 0);
}
.gender-card-selected .gender-card-icon-bg {
    background: #B262FD;
}

    .gender-card-check-container {
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #B262FD;
        top: -12px;
        right: -12px;
        padding: 5px 5px;
        box-shadow: 0px 2px 6px 3px rgba(0, 0, 100, 0.08);
    }
        .gender-card-check {
            font-size: 15px !important;
        }
    .gender-card-inner {
        display: block;
        height: 100%;
        align-items: center; /* align vertical */
        width: 100%;
        padding: 30px 0px;
    }
        .gender-card-title-container {
            margin-top: -4px;
            width: 100%;

        }
            .gender-card-title-container h3 {
                font-size: 22px;
                color: #ffffff;
                margin: 0px;
                width: 100%;
                text-align: center;

            }


@media only screen and (min-width: 1500px) {
    .gender-card {
        width: calc(50% - 16px);
    }
}
@media only screen and (max-width: 1500px) {
    .gender-card {
        width: calc(50% - 16px);
    }
}

@media only screen and (max-width: 1200px) {
    .gender-card {
        width: calc(50% - 16px);
    }
    /*.gender-card-title-container h3 {
        font-size: 18px;
        margin-top: 4px;
    }*/
}

@media only screen and (max-width: 992px) {
    .gender-card {
        width: calc(50% - 16px);
    }
}

@media only screen and (max-width: 768px) {
    .gender-card {
        width: calc(100% - 20px);
    }
}

@media only screen and (max-width: 576px) {
    .gender-card {
        width: calc(100% - 20px);
    }
}


/* .weekday-card- */

.weekday-card {
	display: inline-flex;
	background: rgba(255, 255, 255, 0.05);
	border-radius: 12px;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.04);
	width: calc(14.28% - 16px);
	margin: 8px 8px;
	height: 150px;
	border: solid;
	border-width: 2px;
	border-color: #2E3142;
	position: relative;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.weekday-card:hover {
	cursor: pointer;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 100, 0.06);
	border-color:  #7278F2;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.weekday-card-selected {
	background: rgba(80, 49, 213, 0.6);
	border-color: #5031D5;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 100, 0);
}
	.weekday-card-selected h3 {
		color: #ffffff !important;
	}
.weekday-card-selected .weekday-card-icon-bg {
	background: #5031D5;
}

	.weekday-card-check-container {
		position: absolute;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background: #5031D5;
		top: -12px;
		right: -12px;
		padding: 5px 5px;
		box-shadow: 0px 2px 6px 3px rgba(0, 0, 100, 0.08);
	}
		.weekday-card-check {
			font-size: 15px !important;
		}
	.weekday-card-inner {
		display: block;
		height: 150px;
		align-items: center; /* align vertical */
		width: 100%;
		padding: 62px 0px;
	}
		.weekday-card-title-container {
			margin-top: -4px;
			width: 100%;

		}
			.weekday-card-title-container h3 {
			    font-size: 22px;
			    color: #707484;
			    margin: 0px;
			    width: 100%;
			    text-align: center;
			}


@media only screen and (min-width: 1500px) {
	.weekday-card {
		width: calc(14.28% - 16px);
	}
}
@media only screen and (max-width: 1500px) {
	.weekday-card {
		width: calc(14.28% - 16px);
	}
}

@media only screen and (max-width: 1200px) {
	.weekday-card {
		width: calc(14.28% - 16px);
	}
	.weekday-card-title-container h3 {
		font-size: 18px;
		margin-top: 4px;
	}
}

@media only screen and (max-width: 992px) {
	.weekday-card {
		width: calc(100% - 24px);
	}
}

@media only screen and (max-width: 768px) {
	.weekday-card {
		width: calc(100% - 20px);
	}
}

@media only screen and (max-width: 576px) {
	.weekday-card {
		width: calc(100% - 20px);
	}
}



input[type=text]:focus {
  border: none;
}

.page-form-inner {
	/*height: calc(100vh);
	overflow-y: scroll;*/
	/*padding-bottom: 80px;*/
	/* Hide scrollbars in webkit-based browsers */
}

	.snurra {
		margin-left: 40px;
	}
	.page-form-inner-loading-container {
		width: 100%;
	}
		.page-form-inner-loading-container p {
			width: 100%;
		}

	.page-form-inner-question-container {
/*		padding-top: 25vh;*/
			padding-top: 100px;
	}
	.page-form-inner-question-container-sm-padding {
		padding: 0px 24px;
		padding-top: 100px;

	}
		.page-form-inner-question-container h2 {
			color: #ffffff;
			width: 100%;
			font-size: 42px;
			font-weight: 600;
			margin: 0px;
			margin-bottom: 6px;
			text-align: center;
		}
		.page-form-inner-question-container p {
			width: 100%;
			color: #707484;
			font-size: 22px;
			margin: 0px;
			text-align: center;
		}
		.page-form-inner-question-container h4 {
			width: 100%;
			font-size: 22px;
			color: #7278F2;
			margin: 0px;
			margin-bottom: 6px;
			text-align: center;
			font-weight: 400;
		}



		.page-form-inner-input-container {
			width: 80%;
			margin: 0px auto;
			padding-top: 60px;
		}
			.page-form-inner-input-text {
				width: 100%;
				max-width: 600px;
				margin: 0px auto;
				background: #1A1C24A0;
				border-radius: 12px;
				box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.02);
				padding: 12px 12px;
				display: flex;
				border-width: 1px !important;
				border: solid;
				border-color: #2E3142;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.page-form-inner-input-text-focused {
				border-color: #5031D5;
				background: #1A1C24;
				box-shadow: 0px 0px 12px 6px rgba(12, 12, 252, 0.06) !important;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.page-form-inner-input-text-ion-wrapper {
					width: calc(100% - 50px);
					padding: 0px 6px;
				}
					.page-form-inner-input-text-ion-wrapper ion-input {
						color: #ffffff;
						font-size: 24px;
					}
				.page-form-inner-input-text-button {
					width: 50px;
					height: 50px;
					background: #5031D5;
					border-radius: 6px;
					box-shadow: 0px 0px 12px 6px rgba(0, 0, 24, 0.1);
					padding: 12px 13px;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.page-form-inner-input-text-button:hover {
					cursor: pointer;
					background: #7278F2;
					box-shadow: 0px 0px 6px 3px rgba(0, 0, 24, 0.1);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.page-form-inner-input-text-button-unselected {
					background: #ffffff30;
					box-shadow: 0px 0px 6px 3px rgba(0, 0, 24, 0);
				}
				.page-form-inner-input-text-button-unselected:hover {
					cursor: not-allowed;
					background: #ffffff50;
					box-shadow: 0px 0px 6px 3px rgba(0, 0, 24, 0);
				}
					.page-form-inner-input-text-button-icon {
						font-size: 24px !important;
						color: #ffffff;
					}
					.page-form-inner-input-text-button-unselected .page-form-inner-input-text-button-icon {

					}

			.page-form-inner-input-enter-icon-container {
				width: 100%;
				max-width: 600px;
				margin: 0px auto;
				padding-right: 12px;
				margin-top: 12px;
			}
				.page-form-inner-input-enter-icon {
					background: rgba(255, 255, 255, 0.08);
					padding: 4px 8px;
					width: 50px;
					border-radius: 5px;
					float: right;
					margin-left: auto;
					border: solid;
					border-width: 1px;
					border-color: rgba(0, 0, 0, 0.16);
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
				.page-form-inner-input-enter-icon-hide {
					opacity: 0.0;
					-webkit-transition: all 0.15s;
					-moz-transition: all 0.15s;
					transition: all 0.15s;
				}
					.page-form-inner-input-enter-icon p {
						color: #9DA0AD;
						margin: 0px;
						font-size: 12px;
						width: 100%;
						text-align: center;
					}


	.page-form-inner-confirm-button {
	    padding: 20px 32px;
	    padding-right: 16px;
	    background: #5031D5;
	    display: flex;
	    border-radius: 16px;
	    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.12);
	    -webkit-transition: all 0.15s;
	    -moz-transition: all 0.15s;
	    transition: all 0.15s;
	    float: right;
	    position: fixed;
	    bottom: 24px;
	    right: 60px;
	    height: 62px;
	    z-index: 30;
	}
		.page-form-inner-confirm-button p {
			font-size: 18px;
			font-weight: 500;
			color: #ffffff;
			margin: 0px;
		}
		.page-form-inner-confirm-button-icon {
			font-size: 22px !important;
			color: #ffffff;
			margin-left: 16px;
			margin-top: 1px;
		}
	.page-form-inner-confirm-button:hover {
	    background: #7278F2;
	    cursor: pointer;
	    -webkit-transition: all 0.15s;
	    -moz-transition: all 0.15s;
	    transition: all 0.15s;
	}




@media only screen and (min-width: 1500px) {
	.page-form-inner-confirm-button {
		bottom: 24px;
	    right: 60px;
	}
}
@media only screen and (max-width: 1500px) {
	.page-form-inner-confirm-button {
		bottom: 24px;
	    right: 60px;
	}
}

@media only screen and (max-width: 1200px) {
	.page-form-inner-confirm-button {
		bottom: 24px;
	    right: 60px;
	}
}

@media only screen and (max-width: 992px) {
	.page-form-inner-confirm-button {
		top: 14px;
		right: 12px;
		height: 48px;
		padding: 12px 24px;
	    padding-right: 12px;
	}
		.page-form-inner-confirm-button p {
			font-size: 20px;
		}
		.page-form-inner-confirm-button-icon {
			font-size: 20px;
			margin-top: 0px;
		}
}

@media only screen and (max-width: 768px) {
	.weekday-card {
		width: calc(100% - 20px);
	}
}

@media only screen and (max-width: 576px) {
	.weekday-card {
		width: calc(100% - 20px);
	}
	.page-form-inner-input-container {
		width: 100% !important;
	}
	.page-form-inner {
		padding-bottom: 140px !important;
	}
}










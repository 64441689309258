
.membership-select {
    display: flex;
    padding: 0px 60px;
}

    .membership-select-left-container {
        width: 50%;
        height: 100vh;
        padding-top: calc(80px + 6vh);
        padding-right: 60px;
        overflow-y: scroll;
    }
        .membership-select-left-content {
            width: 100%;
            height: 100%;
        }
            .membership-select-left-image-container {
                width: 18vw;
                height: 18vw;
                background: #000000;
                border-radius: 24px;
                overflow: hidden;
                min-width: 180px;
                min-height: 180px;
            }
                .membership-select-left-image {
                    width: 100%;
                }
            .membership-select-left-info-container {
                padding-bottom: 60px;

            }
                .membership-select-left-info-container h2 {
                    font-size: 72px;
                    line-height: 66px;
                    color: #ffffff;
                    margin: 24px 0px;
                }
                .membership-select-left-info-container p {
                    font-size: 22px;
                    color: #707484;
                    margin: 0px;
                }


    .membership-select-right-container {
        width: 50%;
        padding-top: 80px;
        padding-bottom: 40px;
        height: 100vh;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .membership-select-right-container::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
        .membership-card {
            width: 100%;
            border-radius: 32px;
            background: #ffffff;
            padding: 40px 32px;
        }
            .membership-card-header {
                width: 100%;
                padding-bottom: 40px;
            }
                .membership-card-header h2 {
                    font-size: 32px;
                    color: #000000;
                    margin: 0px;
                    padding-bottom: 12px;
                }
                .membership-card-header p {
                    font-size: 18px;
                    color: #000000;
                    margin: 0px;
                }
                .membership-card-header a {
                    font-size: 18px;
                    font-weight: 500;
                    color: #7278F2;
                    margin: 0px;
                    text-decoration: none;
                }
                .membership-card-header a:hover {
                    color: #5B62FF;
                    text-decoration: underline;
                }
                .membership-card-header-back-button {
                    margin-bottom: 4px;
                    margin-left: -8px;
                    padding: 4px 8px;
                    background: rgba(0, 0, 0, 0);
                    width: 154px;
                    border-radius: 8px;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                .membership-card-header-back-button:hover {
                    background: rgba(0, 0, 0, 0.12);
                    cursor: pointer;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    transition: all 0.15s;
                }
                    .membership-card-header-back-button p {
                        font-size: 14px;
                        color: #707484;
                        margin: 0px;
                        white-space: nowrap;
                    }
            .membership-card-options-wrapper {
                width: 100%;
                border-top: solid;
                border-width: 1px;
                border-color: #00000010;
            }

    /*   Membership payment specific classes     */
    .membership-card-order-summary-container {
        width: 100%;
    }
        .membership-card-order-summary-header {
            width: 100%;
            display: flex;
        }
            .membership-card-order-summary-header-title {
                padding-bottom: 20px;
            }
                .membership-card-order-summary-header-title h3 {
                    font-size: 22px;
                    color: #000000;
                    margin: 0px;
                }
            .membership-card-order-summary-header-value {
                padding-top: 8px;
                margin-left: auto;
                float: right;
            }
                .membership-card-order-summary-header-value p {
                    font-size: 14px;
                    color: #9DA0AD;
                    margin: 0px;
                }
            .membership-card-order-summary-content {
                border-top: solid;
                border-bottom: solid;
                border-width: 1px;
                border-color: #00000010;
                padding: 40px 0px;
                width: 100%;
            }
                .membership-card-order-summary-row-container {
                    width: 100%;
                    display: flex;
                }
                    .membership-card-order-summary-row-header {
                        padding-right: 24px;
                    }
                        .membership-card-order-summary-row-header h3 {
                            font-size: 22px;
                            color: #707484;
                            font-weight: 400;
                            margin: 0px;
                        }
                        .membership-card-order-summary-row-header p {
                            font-size: 14px;
                            color: #5B62FF;
                            font-weight: 500;
                            margin: 0px;
                            padding: 4px 0px;
                        }
                    .membership-card-order-summary-row-value {
                        float: right;
                        margin-left: auto;
                        padding-top: 8px;
                    }
                        .membership-card-order-summary-row-value h4 {
                            font-size: 22px;
                            color: #707484;
                            font-weight: 400;
                            margin: 0px;
                        }
            .membership-card-order-summary-total-container {
                width: 100%;
                padding: 40px 0px;
            }
                .membership-card-order-summary-total-row {
                    width: 100%;
                    display: flex;
                }
                    .membership-card-order-summary-total-row h4 {
                        font-size: 22px;
                        color: #5B62FF;
                        font-weight: 600;
                        margin: 0px;
                    }
                    .membership-card-order-summary-total-row h3 {
                        font-size: 22px;
                        color: #5B62FF;
                        font-weight: 600;
                        margin: 0px;
                        float: right;
                        margin-left: auto;
                    }
                .membership-card-order-summary-total-info {
                    width: 100%;
                    padding-top: 12px;
                }
                    .membership-card-order-summary-total-info p {
                        font-size: 14px;
                        color: #9DA0AD;
                        margin: 0px;
                    }
            .membership-card-billing-details-container {
                width: 100%;
                padding-top: 40px;
                margin-top: 40px;
                border-top: solid;
                border-width: 1px;
                border-color: #00000010;
            }
                .membership-card-billing-details-header {
                    width: 100%;
                    padding-bottom: 40px;
                }
                    .membership-card-billing-details-header h3 {
                        font-size: 22px;
                        color: #000000;
                        font-weight: 500;
                        margin: 0px;
                    }
                    .membership-card-billing-details-header p {
                        font-size: 18px;
                        color: #707484;
                        margin: 0px;
                        padding: 12px 0px;
                    }




@media only screen and (min-width: 1500px) {
    
}
@media only screen and (max-width: 1500px) {
    
}

@media only screen and (max-width: 1200px) {
    
}

@media only screen and (max-width: 992px) {
    .membership-select {
        display: block;
    }
    .membership-select-left-container-payment {
        display: none;
    }
    .membership-select-left-container {
        width: 100%;
        height: auto;
        padding-top: 80px;
        padding-right: 0px;
    }
    .membership-select-left-info-container {
        padding-bottom: 24px;
    }
        .membership-select-left-info-container h2 {
            width: 100%;
            font-size: 48px;
            line-height: 44px;
            text-align: center;
            margin: 16px 0px;
        }
        .membership-select-left-info-container p {
            width: 100%;
            font-size: 18px;
            text-align: center;
        }
        .membership-select-left-image-container {
            margin: 0px auto;
        }
    .membership-select-right-container {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        height: auto;
        overflow-y: visible;
    }
    .membership-select-right-container-payment {
        padding-top: 80px !important;
    }
}

@media only screen and (max-width: 768px) {
    .membership-select {
        padding: 0px 30px;
    }
}

@media only screen and (max-width: 576px) {
    .membership-select {
        padding: 0px 16px;
    }
    .membership-card {
        border-radius: 24px;
        padding: 40px 16px;
        padding-bottom: 16px;
    }
}





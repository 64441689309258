.coach-cards-wrapper {
	width: 100%;
	padding: 0px 60px;
	padding-bottom: 60px;
}
	.coach-cards-container {
		width: auto;
		margin: 0px auto;
		display: flex;
		padding-top: 60px;
		justify-content: center;
	}

@media only screen and (min-width: 1500px) {}
@media only screen and (max-width: 1500px) {}
@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 992px) {
    .coach-cards-wrapper {
    	padding: 0px 24px;
		padding-bottom: 60px;
    }
}

@media only screen and (max-width: 768px) {
    .coach-cards-container {
    	display: block;
    	padding-top: calc((100vw - 160px) * 0.4);
    }
    .coach-cards-wrapper {
    	padding: 0px 12px;
		padding-bottom: 30px;
    }
}

@media only screen and (max-width: 576px) {
    
}

.memberhip-element {
    width: 100%;
    padding: 50px 0px;
    border-bottom: solid;
    border-width: 1px;
    border-color: #00000010;
}
    .memberhip-element-content {
        width: 100%;
    }
        .memberhip-element-header-container {
            width: 100%;
        }
            .memberhip-element-header-blurb-row {
                border: solid;
                border-width: 1.5px;
                border-color: #707484C0;
                border-radius: 20px;
                padding: 6px 12px;
                float: left;
            }
            .memberhip-element-header-blurb-row-nodes {
                border-color: #7278F2;
                background: #7278F218;
            }
            .memberhip-element-header-blurb-row-only-training {
                background: #70748418;
            }
                .memberhip-element-header-blurb-row p {
                    font-size: 10px;
                    color: #707484;
                    margin: 0px;
                    font-weight: 700;
                }
                .memberhip-element-header-blurb-row-nodes p {
                    color: #7278F2;
                }
            .memberhip-element-header-title-row {
                width: 100%;
                display: flex;
                padding: 12px 0px;
            }
                .memberhip-element-header-title-container {
                    width: 100%;
                    
                }
                    .memberhip-element-header-title-container h2 {
                        font-size: 26px;
                        font-weight: 600;
                        color: #000000;
                        margin: 0px;
                    }

                .memberhip-element-header-price-container {
                    display: flex;
                    float: right;
                }

                    .memberhip-element-header-price-container h2 {
                        font-size: 26px;
                        font-weight: 400;
                        color: #000000D0;
                        margin: 0px;
                        margin-right: 8px;
                    }
                    .memberhip-element-header-price-container h3 {
                        font-size: 14px;
                        padding-top: 2px;
                        font-weight: 400;
                        color: #9DA0AD;
                        margin: 0px;
                        white-space: nowrap;
                    }

        .memberhip-element-body-container {
            width: 100%;
            padding: 12px 0px;
        }
            .memberhip-element-body-container p {
                font-size: 16px;
                color: #707484;
                margin: 0px;
            }
        .memberhip-element-cta-wrapper {
            width: 100%;
            min-height: 50px;
            margin-top: 24px;
        }
            .memberhip-element-button-container {
                padding: 12px 20px;
                padding-right: 12px;
                border-radius: 14px;
                border: solid;
                border-width: 2px;
                background: #5B62FF;
                float: left;
                display: flex;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
            .memberhip-element-button-container:hover {
                background: #7278F2;
                cursor: pointer;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                transition: all 0.15s;
            }
                .memberhip-element-button-text-container {
                    padding-right: 14px;
                }
                    .memberhip-element-button-text-container p {
                        color: #ffffff;
                        font-size: 18px;
                        font-weight: 500;
                        margin: 0px;
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;
                    }
                    .memberhip-element-button-container:hover .memberhip-element-button-text-container p {
                        color: #ffffff;
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        transition: all 0.15s;
                    }
                .memberhip-element-button-icon-container {
                    width: 22px;
                    height: 22px;
                    border-radius: 6px;
/*                    background: #5B62FF;*/
/*                    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.06);*/
                    padding: 0px;
                }
                .memberhip-element-button-container:hover .memberhip-element-button-icon-container {
                    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0);
                }
        .memberhip-element-sold-out-container {
            width: 100%;
        }
            .memberhip-element-sold-out-text-container {

            }
                .memberhip-element-sold-out-text-container h4 {
                    font-size: 18px;
                    color: #9DA0AD;
                    margin: 0px;
                }
            .memberhip-element-sold-out-next-steps-text-container {
                width: 100%;
                padding: 12px 0px;
            }
                .memberhip-element-sold-out-next-steps-text-container p {
                    color: #5B62FF;
                    font-size: 14px;
                    margin: 0px;
                }
                .memberhip-element-sold-out-next-steps-text-container span {
                    font-weight: 700;
                    color: #5B62FF;
                }
                .memberhip-element-sold-out-next-steps-text-container span:hover {
                    font-weight: 700;
                    color: #7278F2;
                    text-decoration: underline;
                    cursor: pointer;
                }


@media only screen and (min-width: 1500px) {
    
}
@media only screen and (max-width: 1500px) {
    
}

@media only screen and (max-width: 1200px) {
    
}

@media only screen and (max-width: 992px) {
    
}

@media only screen and (max-width: 768px) {
    .memberhip-element-button-container {
        width: 100%;
    }
    .memberhip-element-button-icon-container {
        float: right;
        margin-left: auto;
    }
}

@media only screen and (max-width: 576px) {
    .memberhip-element-header-title-row {
        padding-top: 40px;
        padding-bottom: 50px;
        display: block;
    }
    .memberhip-element-header-price-container {
        padding: 8px 0px;
        display: flex;
        float: left;
    }
}





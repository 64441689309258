.calendar {
	width: 100%;
	height: calc(100vh - 12px);
}

.calendar-container {
	width: 100%;
	height: 100%;
	display: flex;
}
	.calendar-container-left {
		width: 50%;
		height: 100%;
		padding: 60px 48px;
	}
		.calendar-container-left-header-container {
			width: 100%;
			padding-top: calc(45vh - 220px);
		}
			.calendar-container-left-header-container h2 {
				font-size: 5.3vw;
				line-height: 4.7vw;
				font-weight: 700;
				color: #ffffff;
				margin: 0px;
			}
			.calendar-container-left-header-container p {
				font-size: 20px;
				line-height: 22px;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 28px;
				width: 80%;
			}
			.calendar-container-left-header-container span {
				color: #7278F2;
			}
	.calendar-container-right {
		width: 50%;
		height: 100%;
		padding: 60px 48px;
		padding-top: 80px;
	}
		.calendar-content {
			width: 100%;
			height: 100%;
		}
		iframe {
			overflow: hidden;
			border-radius: 24px;
		}
	

.calendly-inline-widget {
	width: 100% !important;
	height: 100% !important;
	border-radius: 24px !important;
	overflow: hidden !important;
}


@media only screen and (min-width: 1500px) {
	
}
@media only screen and (max-width: 1500px) {
	
}

@media only screen and (max-width: 1200px) {
	
}

@media only screen and (max-width: 992px) {
	.calendar-container {
		display: block;
	}
	.calendar-container-left {
		width: 100%;
		height: auto;
		padding: 60px 24px;
	}
	
	.calendar-container-left-header-container {
		padding-top: 60px;
	}
	.calendar-container-left-header-container h2 {
		font-size: 50px;
		line-height: 42px;
		font-weight: 700;
		color: #ffffff;
		margin: 0px;
	}

	.calendar-container-left-header-container p {
		width: 100%;
	}

	.calendar-container-right {
		width: 100%;
		padding: 0px 24px;
		padding-bottom: 0px;
		height: 1000px;
	}
	.calendar-content {
		height: 1200px;
		padding-bottom: 160px;
	}
}

@media only screen and (max-width: 768px) {
	.calendar-container-left {
		padding: 40px 12px;
	}
	.calendar-container-right {
		padding: 0px 12px;
	}
}

@media only screen and (max-width: 576px) {
	
}

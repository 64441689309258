@font-face {
	font-family: 'Sequel Sans';
	src: url('https://assets.website-files.com/5ee7f9eb35bb1852519f534e/61229d95ea7a9504aec03ce3_SequelSans-Display-Normal.woff2') format('woff');
}

h1, h2, h3, h4, h5, p, span {
	font-family: 'Helvetica Neue', 'Helvetica', 'Sequel Sans', 'Arial', Arial, 'Verdana', Verdana, sans-serif;
}
h1, h2, h3, h4, h5 {
	color: #ffffff;
}
p {
	color: #9DA0AD;
}
span {
	color: #B262FD;
}

body {
	background: #000000;
}

.main {
	background: linear-gradient(-15deg, #5031D5A0 0%, #5031D560 20%, #000 60%, #000 100%);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	width: 100vw;
	height: 100vh;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 50% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.main-content-wrapper {
	height: 100vh;
	padding-top: 100px;
	overflow-y: scroll;
	padding-bottom: 80px;
}

.main-content-wrapper-no-padding {
	padding-top: 0px !important;
	padding-bottom: 0px !important
}

.main-content-no-padding {
	width: 100vw !important;
	height: 100vh;
}

@media only screen and (min-width: 1500px) {
	.main-content {
		width: 1320px;
		margin: 0px auto;
	}
	.main-content-wrapper {
		padding-bottom: 64px;
	}
}
@media only screen and (max-width: 1500px) {
	.main-content {
		width: 1140px;
		margin: 0px auto;
	}
	.main-content-wrapper {
		padding-bottom: 64px;
	}
}

@media only screen and (max-width: 1200px) {
	.main-content {
		width: 960px;
		margin: 0px auto;
	}
	.main-content-wrapper {
		padding-bottom: 64px;
	}
}

@media only screen and (max-width: 992px) {
	.main-content {
		width: 720px;
		margin: 0px auto;
	}
	.main-content-wrapper {
		padding-bottom: 64px;
	}
}

@media only screen and (max-width: 768px) {
	.main-content {
		width: 540px;
		margin: 0px auto;
	}
	.main-content-wrapper {
		padding-bottom: 100px;
	}
}

@media only screen and (max-width: 576px) {
	.main-content {
		max-width: 540px;
		width: calc(100% - 24px);
		margin: 0px auto;
	}
	.main-content-wrapper {
		padding-bottom: 100px;
	}
}

	.main-header-progress-bar {
		height: 8px;
		position: absolute;
		z-index: 20;
		background: #5031D5;
		min-width: 1px !important;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		transition: all 0.3s;
	}

	.main-header-wrapper {

	}

	.main-header {
		width: 100%;	
		padding: 12px 0px;
		padding-top: 20px;
		position: fixed;
		height: 80px;
		z-index: 10;
		background: rgba(0, 0, 0, 0.5);
		-webkit-backdrop-filter: blur(8px);
		backdrop-filter: blur(8px);
		box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0);
	}
	.main-header-transparent {
		background: rgba(0, 0, 0, 0);
		-webkit-backdrop-filter: blur(0px);
		backdrop-filter: blur(0px);
	}
		.main-header-logo-container {

		}
			.main-header-logo-container h3 {
				color: #ffffff;
				font-size: 24px;
				margin: 0px;
				font-weight: 500;
			}
			.main-header-logo-container span {
				color: #ffffff60;
				font-size: 24px;
				font-weight: 500;
				margin: 0px;
			}
			.main-header-logo-container p {
				color: #707484;
				font-size: 14px;
				margin: 0px;
			}

	.main-content-container {
		margin-top: -80px;
		z-index: 0;
	}
	.main-content-container-no-padding {
		margin-top: 0px !important;
		z-index: 0;
	}




/* CSS Transitions */

.slide-enter {
	transform: translateY(-80%);
	opacity: 0;
}
/* This is where we can add the transition*/
.slide-enter-active {
	transform: translateY(0px);
	opacity: 1.0;
	transition: all 500ms;
}
/* This fires as soon as the this.state.showList is false */
.slide-exit {
	transform: translateY(0px);
	opacity: 1.0;
}
/* fires as element leaves the DOM*/
.slide-exit-active {
	transform: translateY(80%);
	opacity: 0.0;
	transition: all 500ms;
}










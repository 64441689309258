/* .style-card- */

.style-card {
	display: inline-flex;
	background: #1A1C24;
	border-radius: 12px;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.04);
	width: calc(33%);
	margin: 2px 2px;
	height: 150px;
	border: solid;
	border-width: 2px;
	border-color: transparent;
	position: relative;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}
.style-card:hover {
	cursor: pointer;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 100, 0.06);
	border-color: #5031D5;
	background: #232836;
	-webkit-transition: all 0.15s;
	-moz-transition: all 0.15s;
	transition: all 0.15s;
}

.style-card-selected {
	background: rgba(196, 138, 255, 0.2);
	border-color: #5031D5;
	box-shadow: 0px 0px 12px 6px rgba(0, 0, 100, 0);
}
.style-card-selected .style-card-icon-bg {
	background: #B262FD;
}

	.style-card-check-container {
		position: absolute;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background: #5031D5;
		top: -12px;
		right: -12px;
		padding: 5px 5px;
		box-shadow: 0px 2px 6px 3px rgba(0, 0, 100, 0.08);
	}
		.style-card-check {
			font-size: 15px !important;
		}
	.style-card-inner {
		display: flex;
		padding: 24px;
		height: 150px;
		align-items: center; /* align vertical */
	}
		.style-card-icon-container {

		}
			.style-card-icon-bg {
				width: 50px;
				height: 50px;
				background: #5031D5;
				border-radius: 5px;
				padding: 9px;
			}
				.style-card-icon-bg img {
					filter: invert(1);
					width: 32px;
					height: 32px;
				}


		.style-card-title-container {
			padding-left: 20px;
			margin-top: -4px;
		}
			.style-card-title-container h3 {
			    font-size: 22px;
			    color: #ffffff !important;
			    margin: 0px;
			}


@media only screen and (min-width: 1500px) {
	.style-card {
		width: calc(33% - 16px);
	}
}
@media only screen and (max-width: 1500px) {
	.style-card {
		width: calc(33% - 16px);
	}
}

@media only screen and (max-width: 1200px) {
	.style-card {
		width: calc(50% - 16px);
	}
}

@media only screen and (max-width: 992px) {
	.style-card {
		width: calc(50% - 16px);
	}
}

@media only screen and (max-width: 768px) {
	.style-card {
		width: calc(100% - 16px);
	}
}

@media only screen and (max-width: 576px) {
	.style-card {
		width: calc(100% - 16px);
	}
}


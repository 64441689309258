.intro {
	width: 100%;
	min-height: 100vh;
	display: flex;
	position: relative;
}

	.intro-page-left-container {
		width: 50%;
		height: 100vh;
		min-height: 100%;
		overflow: hidden;
		
	}
		.intro-page-left-image-wrapper {
			width: auto;
			height: 100%;
			min-height: 100%;
			position: relative;
		}
			.intro-page-left-image {
				position: absolute;
			    top: 50%;
			    left: 50%;
			    transform: translateX(-50%) translateY(-50%);
			    width: 100%;
			    min-height: 100%;
			    object-fit: cover;
			}

	.intro-page-right-container {
		width: 50%;
		height: 100vh;
		min-height: 100%;
		overflow: hidden;
		padding: 100px 60px;
		padding-top: 0px;
		display: flex;
		flex-direction: column;
		background: black;
	}
		.intro-page-right-header-container {
			padding-top: calc(50vh - 190px);
		}
			.intro-page-right-header-container h2 {
				font-size: 5.3vw;
				line-height: 4.7vw;
				font-weight: 700;
				color: #ffffff;
				margin: 0px;
			}
			.intro-page-right-header-container p {
				font-size: 20px;
				line-height: 22px;
				color: #9DA0AD;
				margin: 0px;
				margin-top: 28px;
				width: 80%;
			}
		.intro-page-right-button-container {
			width: 100%;
			margin-top: auto;
			vertical-align: bottom;
			padding-top: 24px;
		}
			.intro-page-right-button {
				background: #5031D5;
				border-radius: 16px;
				padding: 20px 32px;
				float: left;
				position: relative;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
			.intro-page-right-button:hover {
				background: #7278F2;
				cursor: pointer;
				-webkit-transition: all 0.15s;
				-moz-transition: all 0.15s;
				transition: all 0.15s;
			}
				.intro-page-right-button p {
					font-size: 18px;
					font-weight: 500;
					color: #ffffff;
					margin: 0px;
					margin-right: 60px;
				}

				.intro-page-right-button-icon-container {
					position: absolute;
					right: 0;
					top: 0;
					height: 100%;
				}	
					.intro-page-right-button-icon {
						height: 90%;
						margin-top: 5%;
						margin-right: 2px;
					}



@media only screen and (min-width: 1500px) {
	
}
@media only screen and (max-width: 1500px) {
	
}

@media only screen and (max-width: 1200px) {
	
}

@media only screen and (max-width: 992px) {
	.intro-page-left-container {
		width: 100%;
		opacity: 0.3;
	}
	.intro-page-right-container {
		position: absolute;
		width: 100%;
		padding: 0px 24px;
		padding-bottom: 160px;
		left: 0px;
		right: 0px;
		top: 0px;
		bottom: 0px;
		background: transparent;
	}
	.intro-page-right-header-container {
		padding-top: 100px;
	}
	.intro-page-right-header-container h2 {
		font-size: 50px;
		line-height: 42px;
		font-weight: 700;
		color: #ffffff;
		margin: 0px;
	}

	.intro-page-right-header-container p {
		width: 100%;
	}

	.intro-page-right-button {
		width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	
}

@media only screen and (max-width: 576px) {
	
}



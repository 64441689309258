


.page-form-inner-loading-container {
	width: 200px;
	margin: 0px auto;
}
	.page-form-inner-loading-container p {
		width: 100%;
		text-align: center;
		padding-bottom: 4px;
	}
	.gegga {
	  width: 0;
	}

	.snurra {
	  filter: url(#gegga);
	}

	.stopp1 {
	  stop-color: #B262FD;
	}

	.stopp2 {
	  stop-color: #5B62FF;
	}

	.halvan {
	  animation: Snurra1 10s infinite linear;
	  stroke-dasharray: 180 800;
	  fill: none;
	  stroke: url(#gradient);
	  stroke-width: 23;
	  stroke-linecap: round;
	}

	.strecken {
	  animation: Snurra1 3s infinite linear;
	  stroke-dasharray: 26 54;
	  fill: none;
	  stroke: url(#gradient);
	  stroke-width: 23;
	  stroke-linecap: round;
	}

	.skugga {
	  filter: blur(5px);
	  opacity: 0.3;
	  position: absolute;
	  transform: translate(3px, 3px);
	}

	@keyframes Snurra1 {
	  0% {
	    stroke-dashoffset: 0;
	  }

	  100% {
	    stroke-dashoffset: -403px;
	  }
	}

.page-form-inner-content-container {
	width: 100%;
	max-width: 500px;
	margin: 0px auto;
}

	.program-weekday-header {
		padding: 8px 0px;
	}
		.program-weekday-header h2 {
		    font-size: 28px;
		    color: #ffffff;
		    margin: 0px;
		}
		.program-restday {
			padding: 24px 24px;
			background: rgba(255,255,255,0.8);
		    border-radius: 12px;
		    box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.04);
		    width: 100%;
		    margin: 8px 0px;
		    margin-bottom: 24px;
		    border: solid;
		    border-width: 2px;
		    border-color: transparent;
		    position: relative;
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		}
			.program-restday h4 {
			    font-size: 22px;
			    font-weight: 400;
			    color: #454751;
			    margin: 0px;
			}
		.program-content-container-header {
			padding: 28px 24px;
			background: #ffffff;
		    border-radius: 12px;
		    box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.04);
		    width: 100%;
		    margin: 8px 0px;
		    margin-bottom: 24px;
		    border: solid;
		    border-width: 2px;
		    border-color: transparent;
		    position: relative;
		    -webkit-transition: all 0.15s;
		    -moz-transition: all 0.15s;
		    transition: all 0.15s;
		}
			.program-content-container-header h4 {
			    font-size: 22px;
			    font-weight: 600;
			    color: #000000;
			    margin: 0px;
			    padding-bottom: 16px;
			}
			.program-content-container-header span {
				color: #454751;
				font-weight: 400;
			}
			.program-content-exercise-container {
				padding: 8px 0px;
				padding-left: 20px;
				position: relative;
			}
				.program-content-exercise-container h4 {
					font-size: 18px;
					font-weight: 500;
				    color: #000000;
				    margin: 0px;
				    padding-bottom: 4px;

				}
					.exercise-curtain {
						position: absolute;
						z-index: 2;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;
						/*background: rgba(255, 255, 255, 0.5);*/
						-webkit-backdrop-filter: blur(6px);
						backdrop-filter: blur(6px);
					}
				.program-content-set-container {
					padding: 4px 0px;
					padding-left: 20px;
					position: relative;
					z-index: 0;
				}
					.program-content-set-container p {
					    font-size: 15px;
					    color: #454751;
					    margin: 0px;
					}
					.program-content-set-container span {
						color: #B262FD;
					}


	.page-form-inner-content-rebuild-button {
		width: 100%;
		background: #B262FD;
		padding: 14px;
		margin-top: 32px;
		border-radius: 5px;
		box-shadow: 0px 0px 6px 3px rgba(0, 0, 50, 0.08);
		color: #ffffff;
		font-size: 18px;
		-webkit-transition: all 0.15s;
		-moz-transition: all 0.15s;
		transition: all 0.15s;
	}
		.page-form-inner-content-rebuild-button:hover {
			background: #C486FF;
			cursor: pointer;
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.page-form-inner-content-rebuild-button p {
			color: #ffffff;
			font-size: 18px;
			margin: 0px;
			width: 100%;
			text-align: center;
		}


	.program-rebuild-container {
		width: 100%;
		display: flex;
	}
		.program-rebuild-container p {
			width: 100%;
			text-align: center;
			color: #D9DCE5;
			font-size: 18px;
			padding: 12px 0px;
		}
		.program-rebuild-container span {
			padding: 4px 8px;
			padding-right: 3px;
			border-radius: 5px;
			background: rgba(91, 98, 255, 0);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.program-rebuild-container span:hover {
			cursor: pointer;
			color: #C486FF;
			background: rgba(196, 134, 255, 0.2);
			-webkit-transition: all 0.15s;
			-moz-transition: all 0.15s;
			transition: all 0.15s;
		}
		.program-rebuild-button-icon {
			font-size: 15px !important;
			padding-top: 2px;
			margin-left: 4px;
		}

		.program-rebuild-button {
			
			display: flex;
			float: left;
		}
			.program-rebuild-button p {
			    font-size: 18px;
			    color: #9DA0AD;
			    margin: 0px;
			}





